/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 function showAlert(type, message, offset) {
   offset = offset || 0;
   var html = '<p class="alert ' + type + '">' + message + '</p>';
   $('#alertContainer').html(html);
   $('#alertContainer').velocity(
     'scroll', {
        duration: 500,
        easing: 'easeInOutQuad',
        offset: offset,
     }
   );
 }

 function padTime(num) {
   if (num < 10) {
     num = '0' + num;
   }
   return num;
 }

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages
                $('.liquid').imgLiquid();

                $('.tabs .tab').click(function() {
                  var name = $(this).attr('data-name');

                  $('.tabs .tab').removeClass('active');
                  $(this).addClass('active');

                  $('.slide').hide();
                  $('.slide[data-name="'+ name + '"]').show();
                });

                $('#range').slider({
                  'min': 0,
                  'max': 100,
                  'value': 100,
                  'tooltip': 'hide',
                });

                $('.trumbowyg').trumbowyg({
                  btns: ['strong', 'em', '|'],
                  svgPath:'/wp-content/themes/ictpartnergezocht/dist/scripts/ui/icons.svg',
                  autogrow: true
                });

                $('.navbar-header .navbar-toggle').click(function(){
                  $('.navbar-header').toggleClass('open');
                });
     
                if(localStorage.getItem('cookie') !== 'false') {
                  $('.cookie_bar').velocity('fadeIn');
                }

                $('.cookie_bar').click(function(){
                  localStorage.setItem('cookie', 'false');
                  $(this).velocity('fadeOut');
                });

                $('.select_boxes .box').click(function(){
                  $(this).parent().find('.active').removeClass('active');
                  $(this).addClass('active');

                  $("input[name='type_form']").val( $(this).attr('data-val') );
                });

                // localstorgae test
                var mod = 'localStorage';
 
                try {
                  localStorage.setItem(mod, mod);
                  localStorage.removeItem(mod);
                } catch(e) {
                   $('#localstorage_modal').velocity('fadeIn');
                }

                $('#close_localstorage').click(function(){
                  $('#localstorage_modal').velocity('fadeOut');
                });

                setTimeout(function() {
                    $('body, html').scrollTop(0);
                    $('.load-overlay').velocity('fadeOut');
                }, 100);

                $(window).scroll(function () {
                    var sticky = $('.navbar'),
                        scroll = $(window).scrollTop();

                    if (scroll >= 100) {
                      sticky.addClass('fixed');
                    } else {
                      sticky.removeClass('fixed');
                    }
                });

                $(document).on('click', '.wc_payment_method', function() {
                    $('.wc_payment_method').removeClass('active');
                    $(this).addClass('active');
                });

                $('.tips-slider').slick({
                  adaptiveHeight: true,
                  arrows: false,
                });

                $('.tips-slider-wrapper .prev').click(function() {
                  $('.tips-slider').slick('slickPrev');
                });

                $('.tips-slider-wrapper .next').click(function() {
                  $('.tips-slider').slick('slickNext');
                });

                var profileProgress = $('#profileProgress').attr('data-progress') * 100;
                $('#profileProgress').velocity({
                  height: profileProgress + '%',
                }, {
                  delay: 1000,
                  duration: profileProgress * 30,
                  easing: "easeInOutQuad",
                });

                $('.scroll-down').click(function () {
                  var scroll = $('.masthead').outerHeight() - $('.navbar').outerHeight();
                  $('body, html').velocity('scroll',{duration: 1000, offset: scroll});
                });

                $('#showPassword').mousedown(function() {
                  $('#passwordInput').attr('type', 'text');
                });

                $('#showPassword').mouseup(function() {
                  $('#passwordInput').attr('type', 'password');
                });

                $(document).on('submit', '#checkout_form', function(){
                  $(this).find(':input[type=submit]').prop('disabled', true);
                  //$(this).remove();
                });

                $('.testimonial-slider').slick({
                  arrows: false,
                  infinite: true,
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  responsive: [
                    {
                      breakpoint: 1140,
                      settings: {
                        slidesToShow: 2,
                      }
                    },
                    {
                      breakpoint: 740,
                      settings: {
                        slidesToShow: 1,
                      }
                    }
                  ]
                });

                $('.testimonials .slick-prev').click(function() {
                  $('.testimonial-slider').slick('slickPrev');
                });

                $('.testimonials .slick-next').click(function() {
                  $('.testimonial-slider').slick('slickNext');
                });

                $('.media-slider').slick({
                  arrows: true,
                  autoscroll: true,
                  infinite: true,
                  slidesToShow: 4,
                  slidesToScroll: 1,
                  autoplay: true,
                  autoplaySpeed: 2000,
                  prevArrow:"<i class='fa fa-angle-left' aria-hidden='true'></i>",
                  nextArrow:"<i class='fa fa-angle-right' aria-hidden='true'></i>",
                  responsive: [
                    {
                      breakpoint: 992,
                      settings: {
                        slidesToShow: 3,
                      }
                    },
                    {
                      breakpoint: 768,
                      settings: {
                        slidesToShow: 2,
                      }
                    }
                  ]
                });

                $('.welcome-slider').slick({
                  prevArrow:"<i class='fa fa-angle-left' aria-hidden='true'></i>",
                  nextArrow:"<i class='fa fa-angle-right' aria-hidden='true'></i>",
                  autoplay: false,
                  arrows: true,
                  dots: true
                });

                $(".field .inside .show").click(function() {

                  $(this).closest('.inside').find('.checkbox-container').toggleClass("full");

                  //changed text of button
                  if ($(this).closest('.inside').find('.checkbox-container').hasClass('full')) {
                    $(this).closest('.inside').find('.show').text('Minder weergeven');
                  } else {
                    $(this).closest('.inside').find('.show').text('Meer weergeven');
                  }

                });
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
                var countdownInterval;

                function countdown(endTime) {
                  var endDate = Date.parse(endTime);
                      today = new Date();
                      countdownString = null;

                  if (today < endDate) {
                    var difference = endDate - today;
                    var seconds = Math.floor(difference / 1000);
                    var minutes = Math.floor(seconds / 60);
                    var hours = Math.floor(minutes / 60);

                    minutes = minutes - (hours * 60);
                    seconds = seconds - (hours * 60 * 60) - (minutes * 60);
                    countdownString = padTime(hours) + ':' + padTime(minutes) + ':' + padTime(seconds);
                  } else {
                    countdownString = '00:00:00';
                  }
                  return countdownString;
                }

                $(document).on('click', '.nav-tabs li', function() {
                    var toggle = $(this).attr('data-toggle');
                    active = $('.partner .more-info .tab-pane.active');
                    current = $('.partner .more-info ' + toggle);
                    active.removeClass('active');

                    $('.nav-tabs li.active').removeClass('active');

                    current.addClass('active');
                    $(this).addClass('active');
                });

                function sizes() {
                  var t = 0;

                  $(".layout--recent-post .article-link").each(function() {
                      div = $(this);
                      div.css('height', 'auto');

                      if (div.outerHeight() > t) {
                          t = div.outerHeight();
                      }
                  });

                  $(".layout--recent-post .article-link").each(function() {
                      $(this).css('height', t);
                  });

                  var t = 0;

                  $(".examples .inner").each(function() {
                      div = $(this);
                      div.css('height', 'auto');

                      if (div.outerHeight() > t) {
                          t = div.outerHeight();
                      }
                  });

                  $(".examples .inner").each(function() {
                      $(this).css('height', t);
                  });
                  
                  var t = 0;

                  $(".examples_archive .inner").each(function() {
                      div = $(this);
                      div.css('height', 'auto');

                      if (div.outerHeight() > t) {
                          t = div.outerHeight();
                      }
                  });

                  $(".examples_archive .inner").each(function() {
                      $(this).css('height', t);
                  });
                }
                sizes();

                $(window).resize(function() {
                    sizes();
                });

                $('.open-lead').click(function() {
                  if ($(this).attr('data-status') === 'false') {
                    $('#modal').html('');

                    $(this).attr('data-status', 'true');
                    var leadID = $(this).attr('data-id');

                    $.post(
                        ipgglobal.ajax_url, {
                          'action': 'open_lead',
                          'nonce': ipgglobal.nonce,
                          'lead': leadID,
                        },
                        function (response) {
                          $('.open-lead').attr('data-status', 'false');

                          if( response.success) {
                            clearInterval(countdownInterval);
                            $('.wrapper-modal').velocity('fadeIn');
                            $('#modal').html(response.output);

                            countdownInterval = setInterval(function() {
                              $('#modal #countdown').html(countdown(response.endTime));
                            }, 500);

                            $('.trumbowyg').trumbowyg({
                              btns: ['strong', 'em', '|'],
                              svgPath:'/wp-content/themes/ictpartnergezocht/dist/scripts/ui/icons.svg',
                              autogrow: true
                            });

                          } else {
                            alert(response.msg);
                          }
                        }
                    );
                  }
                });

                $(document).on('click', '.close-lead', function(){
                  $('.wrapper-modal').velocity('fadeOut', {'duration': 100});

                  setTimeout(function(){
                    $('#modal').html('');
                  }, 150);
                });

                $('.open-vendor').click(function() {
                  if ($(this).prop('disabled') === false) {
                    $(this).prop('disabled', true);
                    var vendorID = $(this).attr('data-id');

                    $.post(
                        ipgglobal.ajax_url, {
                          'action': 'open_vendor',
                          'nonce': ipgglobal.nonce,
                          'vendor': vendorID,
                        },
                        function (response) {
                          $('.open-vendor').prop('disabled', false);

                          if( response.success) {
                            $('#modal').html(response.output);
                          } else {
                            alert(response.msg);
                          }
                        }
                    );
                  }
                });

                $('.faq .border-block .read-more').click(function() {
                  var current = $(this).parents('.border-block');
                  var questions = $('.questions').children('.border-block');

                  questions.each(function() {
                    $(this).velocity(
                      'fadeOut', {
                        complete: function() { current.addClass('active'); },
                        display: 'none',
                        duration: 250,
                      }
                    );
                  });
                  current.velocity(
                    'fadeIn', {
                      display: 'block',
                      duration: 250,
                    }
                  );
                });

                $('.faq .border-block .fa-times').click(function() {
                  var current = $(this).parents('.border-block');
                  var questions = $('.questions').children('.border-block');

                  current.velocity(
                    'reverse', {
                      complete: function() {
                        current.removeClass('active');

                        questions.each(function() {
                          $(this).velocity(
                            'fadeIn', {
                              display: 'block',
                              duration: 250,
                            }
                          );
                        });
                      },
                      duration: 250,
                    }
                  );
                });

                $('#dismissWelcome').click(function() {
                  $('.dashboard-welcome').velocity('fadeOut');
                });

                $('.dashboard-welcome .fa.fa-times').click(function() {
                  $('.dashboard-welcome').velocity('fadeOut');
                });

                var flag = false;
                $(document).on('click touchstart', '.close_overview_modal', function(){
                  if (!flag) {
                    flag = true;

                    $('.opdracht_modal').velocity('fadeOut');

                    setTimeout(function(){ 
                      flag = false; 
                    }, 500);
                  }
                });

                function auto_select(cur) {
                  var partner = cur;

                  var filters;
                      categorie = [];
                      branche = [];
                      isocertificaat = [];
                      werkplekken = '';

                    $('.field.categorie input:checked').each(function() {
                        categorie.push($(this).val());
                    });
                    $('.field.branche input:checked').each(function() {
                        branche.push($(this).val());
                    });
                    $('.field.iso input:checked').each(function() {
                        isocertificaat.push($(this).val());
                    });

                    var postcode = $('.opdracht_modal').attr('data-postcode');
                        distance = $('#range').val();
                        werkplekken = $('.field.werkplekken input[name=werkplekken]:checked').val();
                    
                    filters = {
                      'branches': branche,
                      'distance': distance,
                      'isocertificaten': isocertificaat,
                      'specialisaties': categorie,
                      'postcode': postcode,
                      'werkplekken': werkplekken,
                    };

                    $.post(
                        ipgglobal.ajax_url, {
                          'action': 'get_auto_select',
                          'nonce': ipgglobal.nonce,
                          'filter': filters,
                          'partner': partner
                        },
                        function (response) {
                          $('#auto_select').empty();
                          if (response.success) {
                            $('#auto_select').html(response.data.output);
                            $('.liquid').imgLiquid();

                          } else {
                            showAlert('danger', response.data.message, -100);
                          }
                        }
                    );
                }

                  var flag = false;
                  $(document).on('click touchstart', '.remove_autosel', function() {
                    var obj = $(this);
                    if (!flag) {
                      flag = true;

                      obj.parent().parent().remove();

                      if($('#auto_select .our').length == 0) {
                        $('.our-title').remove();
                      }

                      setTimeout(function(){ 
                        flag = false; 
                      }, 500);
                    }
                    return false;
                  });             

                  var flag = false;
                  $(document).on('click touchstart', '.add-partner', function() {
                    var obj = $(this);
                    if (!flag) {
                      flag = true;

                      obj.find('.add').hide();
                      obj.find('.loader').show();
                      var p_id = obj.attr('data-partner');

                        $.post(
                            ipgglobal.ajax_url, {
                              'action': 'get_partner_modal',
                              'nonce': ipgglobal.nonce,
                              'partner': p_id,
                            },
                            function (response) {
                              $('#opdracht_modal_wrap').html('');
                              $('#alertContainer').empty();

                              console.log(response);
                              if (response.success) {
                            
                                $('#opdracht_modal_wrap').html(response.data.output);
                                $('.opdracht_modal').velocity('fadeIn');
                                $('.liquid').imgLiquid();

                                auto_select(p_id);

                                obj.find('.add').show();
                                obj.find('.loader').hide();
                              } else {
                                showAlert('danger', response.data.message, -100);
                              }
                            }
                        );
                      setTimeout(function(){ 
                        flag = false; 
                      }, 2500);
                    }

                    return false;
                  });

                  function get_street() {
                    var postalCode = $('.modal_wrap input[name="postcode"]').val();

                    if(postalCode.length >= 4) {
                      $.ajax({
                         url: 'https://dutchblueclients.nl/postal/api.php?postal=' + postalCode,
                         type: "GET",
                         jsonpCallback: 'callback',
                         crossDomain: true,
                         success: function (response) {

                           if( response.status === "true") {
                             $('input[name="adres"]').val(response.street + ' ' + $('.modal_wrap input[name="hnummer"]').val() + ', ' + response.place);
                             $('#address').html(response.street + ' ' + $('.modal_wrap input[name="hnummer"]').val() + ', ' + response.place);
                           } else {
                             $('input[name="adres"]').val('Postcode onbekend');
                             $('#address').html('Postcode onbekend');
                           }
                           
                         },
                      });
                    }

                  }

                  $(document).on('keyup', '.modal_wrap input[name="postcode"]', function() {
                    get_street();
                  });

                  $(document).on('keyup', '.modal_wrap input[name="hnummer"]', function() {
                    get_street();
                  });

                  function get_name() {
                    var first = $('.modal_wrap input[name="voornaam"]').val();
                    var last = $('.modal_wrap input[name="achternaam"]').val();

                    $('.modal_wrap input[name="naamContactpersoon"]').val(first + ' ' + last);
                  }

                  $(document).on('keyup', '.modal_wrap input[name="voornaam"]', function() {
                    get_name();
                  });

                  $(document).on('keyup', '.modal_wrap input[name="achternaam"]', function() {
                    get_name();
                  });

                  $(document).on('keyup', '.modal_wrap input[name="kvk"]', function() {
                    var val = $(this).val();
                    var kvkapi = ipgglobal.kvk_api;

                    if(val.length === 8) {
                      $.ajax({
                         url: 'https://overheid.io/api/kvk/'+ val +'/0000?ovio-api-key=' + kvkapi,
                         type: "GET",
                         jsonpCallback: 'callback',
                         crossDomain: true,
                         success: function (response) {
                            console.log(response);
                            if(response.actief == true) {

                              $('.compName').html(response.handelsnaam);
                              $('.compName').show();

                              $('input[name="postcode"]').val(response.postcode);
                              $('input[name="bedrijfsNaam"]').val(response.handelsnaam);
                              $('input[name="adres"]').val(response.straat + ' ' + response.huisnummer + ' ' + response.huisnummertoevoeging + ', ' + response.plaats);

                            } else {
                              $('.compName').hide();
                            }

                         },
                         error: function(r) {
                          $('.compName').hide();
                         }
                      });

                    } else {
                      $('.compName').hide();
                    }
                  });

                  var flag = false;
                  $(document).on('click touchstart', '#plaats_opdracht', function(){

                    var obj = $(this);

                    if (!flag) {
                      flag = true;

                      if(obj.hasClass('load') === false ) {

                      var error = 0;
                      $('.input-tabs input').each(function(){
                        if($(this).val().length === 0 && $(this).hasClass('req')) {

                          if($('#alertContainer').is(':empty')) {
                            $('#alertContainer').html('<p class="alert danger">Niet alle velden zijn juist ingevuld.</p>');
                          }

                          $(this).addClass('error');
                          error = (error + 1);
                        } else {
                          $(this).removeClass('error');
                        }
                      });

                      $('.textarea_field textarea').each(function(){
                        if($(this).val().length === 0 && $(this).hasClass('req')) {

                          if($('#alertContainer').is(':empty')) {
                            $('#alertContainer').html('<p class="alert danger">Niet alle velden zijn juist ingevuld.</p>');
                          }

                          $(this).addClass('error');
                          error = (error + 1);
                        } else {
                          $(this).removeClass('error');
                        }
                      });

                      if(error === 0) {
                        $('#alertContainer').hide();

                        obj.addClass('load');
                        obj.find('.add').hide();
                        obj.find('.loader').show();

                        var leadFormData = {};
                        $.each( $('#leadForm').serializeArray(), function(i, field) {
                          leadFormData[field.name] = field.value;
                        });
                        leadFormData.omschrijvingOpdracht = $('#omschrijvingOpdracht').val();
                        leadFormData = JSON.stringify( leadFormData );

                        var partners = [];
                        $('#auto_select .our').each(function() {
                            partners.push($(this).attr('data-partner'));
                        });

                        partners.push($('.opdracht_modal').attr('data-partner'));

                        var selectedPartners = JSON.stringify(partners);

                        $.post(
                            ipgglobal.ajax_url, {
                              'action': 'create_lead',
                              'nonce': ipgglobal.nonce,
                              'lead': leadFormData,
                              'partners': selectedPartners
                            },
                            function (response) {
                              console.log(response);
                              $('#createLead').prop('disabled', false);

                              if( response.success) {
                                window.location = ipgglobal.site_url + '/aanvraag-aangemaakt?oms=' + response.msg + '&lead_id=' + response.lead_id;
                              } else {
                                obj.removeClass('load');
                                obj.find('.add').show();
                                obj.find('.loader').hide();
                  
                                $('#alertContainer').html('<p class="alert danger">'+ response.msg +'</p>');
                                $('#alertContainer').show();
                                
                              }
                            }
                        );
                      }

                      setTimeout(function(){ flag = false; }, 1000)
                    }
                  }

                  });
            }
        },
        // Home page
        'home': {
            init: function() {
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
                function sizes() {
                  var t = 0;

                  $(".quote_content").each(function() {
                      div = $(this);
                      div.css('height', 'auto');

                      if (div.outerHeight() > t) {
                          t = div.outerHeight();
                      }
                  });

                  $(".quote_content").each(function() {
                      $(this).css('height', t);
                  });

                  var n = 0;

                  $(".testimonial-slider .testmonials-item").each(function() {
                      div = $(this);
                      div.css('height', 'auto');

                      if (div.outerHeight() > n) {
                          n = div.outerHeight();
                      }
                  });

                  $(".testimonial-slider .testmonials-item").each(function() {
                      $(this).css('height', n);
                  });
                }
                sizes();

                $(window).resize(function() {
                    sizes();
                });
            }
        },
        'editor_dashboard': {
          init: function() {
          },
          finalize: function() {
            $('#modal').on('click', '.approve-lead', function(e) {
              e.preventDefault();

              if( $(this).prop('disabled') === false) {
                $(this).prop('disabled', true);
                var leadID = $(this).attr('data-id');
                var leadFormData = {};

                $.each($('#update_lead').serializeArray(), function(i, field) {
                  leadFormData[field.name] = field.value;
                });

                leadFormData = JSON.stringify(leadFormData);

                $.post(
                    ipgglobal.ajax_url, {
                      'action': 'approve_lead',
                      'nonce': ipgglobal.nonce,
                      'lead': leadID,
                      'form': leadFormData
                    },
                    function (response) {
                      $('.approve-lead').prop('disabled', false);

                      if( response.success) {
                        console.log(response.msg);
                        $('.approve-lead').remove();
                        window.location.reload(true);

                      } else {
                        console.log(response.msg);
                      }
                    }
                );
              }

            });

            $('#modal').on('click', '.update-lead', function(e) {
              e.preventDefault();

              if( $(this).prop('disabled') === false) {
                $(this).prop('disabled', true);
                var leadID = $(this).attr('data-id');
                var leadFormData = {};

                $.each($('#update_lead').serializeArray(), function(i, field) {
                  leadFormData[field.name] = field.value;
                });

                leadFormData = JSON.stringify(leadFormData);

                $.post(
                    ipgglobal.ajax_url, {
                      'action': 'update_lead',
                      'nonce': ipgglobal.nonce,
                      'lead': leadID,
                      'form': leadFormData
                    },
                    function (response) {
                      $('.update-lead').prop('disabled', false);

                      if(response.msg === 'opgeslagen') {
                        console.log(response.msg);
                        window.location.reload(true);
                      } else {
                        console.log(response.msg);
                      }
                    }
                );
              }
            });

          }
        },
        'laat_een_review_achter': {
            init: function() {
            },
            finalize: function() {
              var searchParams = new URLSearchParams(window.location.search);
              var token = searchParams.get('token');

              $('.rating .star').click(function() {
                var rating = $(this).attr('data-rating');
                $(this).parents('.rating').find('.star').each(function() {
                  if( $(this).attr('data-rating') <= rating) {
                    $(this).removeClass('empty');
                  } else {
                    $(this).addClass('empty');
                  }
                });
              });

              function getRating( element) {
                var rating = 0;
                element.find('.star').each(function() {
                  if( $(this).attr('data-rating') > rating && !($(this).hasClass('empty'))) {
                    rating = $(this).attr('data-rating');
                  }
                });
                return rating * 2;
              }

              $('#reviewForm').submit(function(event) {
                event.preventDefault();
              });

              $('#createReview').click(function() {

                if( !($(this).hasClass('disabled')) && $('#reviewForm')[0].checkValidity()) {
                  $(this).addClass('disabled');

                  var scorePlanning = getRating( $('#planningRating'));
                  var scoreService = getRating( $('#serviceRating'));
                  var scorePrijsKwaliteit = getRating( $('#prijskwaliteitRating'));

                  if ( !(scorePlanning > 0 && scoreService && scorePrijsKwaliteit > 0)) {
                    showAlert('danger', 'Selecteer voor elke categorie het aantal sterren dat de partner verdient.');
                    $('#createReview').removeClass('disabled');
                    return;
                  }

                  var reviewFormData = {};
                  $.each( $('#reviewForm').serializeArray(), function(i, field) {
                    reviewFormData[field.name] = field.value;
                  });
                  reviewFormData.planningRating = scorePlanning;
                  reviewFormData.serviceRating = scoreService;
                  reviewFormData.prijskwaliteitRating = scorePrijsKwaliteit;
                  reviewFormData = JSON.stringify( reviewFormData );

                  $.post(
                      ipgglobal.ajax_url, {
                        'action': 'create_review',
                        'nonce': ipgglobal.nonce,
                        'review': reviewFormData,
                        'token': token,
                      },
                      function (response) {
                        if( response.success) {
                          window.location = ipgglobal.site_url + '/review-aangemaakt';
                        } else {
                          $('#createReview').removeClass('disabled');
                          showAlert('danger', response.msg);
                        }
                      }
                  );
                }
              });
            }
        },
        'ict_bedrijf_aanmelden': {
          init: function() {
          },
          finalize: function() {

            $('#signupPartner').on('click', function(e) {

              if( $(this).prop('disabled') === false && $('#signupForm')[0].checkValidity()) {
                $(this).prop('disabled', true);
                var password = $('#password').val();
                var confirmPassword = $('#confirmPassword').val();

                var signupFormData = {};
                $.each( $('#signupForm').serializeArray(), function(i, field) {
                  signupFormData[field.name] = field.value;
                });
                signupFormData = JSON.stringify( signupFormData );

                if( password === confirmPassword) {
                  $.post(
                      ipgglobal.ajax_url, {
                        'action': 'signup_partner',
                        'nonce': ipgglobal.nonce,
                        'data': signupFormData
                      },
                      function (response) {
                        if (response.success) {
                          window.location = ipgglobal.site_url + '/aanmelding-ontvangen';
                        } else {
                          $('#signupPartner').prop('disabled', false);
                          showAlert('danger', response.data.message);
                        }
                      }
                  );
                } else {
                  $('#signupPartner').prop('disabled', false);
                  showAlert('danger', 'De wachtwoorden komen niet overeen.');
                }
              } else {
                showAlert('danger', 'Niet alle ingevulde gegevens zijn geldig.');
              }
            });
          }
        },
        'partner_dashboard': {
            init: function() {
            },
            finalize: function() {

            }
        },
        'bedrijven_overzicht': {
            init: function() {
              $('.big_loader .brands').slick({
                arrows: false,
                slidesToShow: 3,
                autoplay: true,
                autoplaySpeed: 2000,
                responsive: [
                  {
                    breakpoint: 600,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2,
                    }
                  }
                ]
              });

              $(function() {
                return $("[data-sticky_column]").stick_in_parent({
                  parent: "[data-sticky_parent]"
                });
              });

              $(window).on("resize", (function(_this) {
                return function(e) {
                  return $(document.body).trigger("sticky_kit:recalc_every");
                };
              })(this));

              $('.close_filter').click(function(){
                $('.partner-filter').hide();
                $('.bedrijven-overzicht .col-md-3.to-left').hide();
              });

              $(document).on('click', '.navbar-partnerselect .filters', function(){
                $('.partner-filter').show();
                $('.bedrijven-overzicht .col-md-3.to-left').show();
                $('body, html').velocity('stop').velocity('scroll', { duration: 0, offset: 0 });
              });

              setTimeout(function() {
                  $('.big_loader.load-modal').velocity('fadeOut', {'duration': 750});
              }, 4500);



                $('.field.toggle .label').click(function(){
                  var obj = $(this).parent();
                  var status = obj.attr('data-status');

                  if(status === 'open') {
                    obj.removeClass('open').addClass('close').attr('data-status', 'close');
                  } else {
                    obj.removeClass('close').addClass('open').attr('data-status', 'open');
                  }

                  $('[data-sticky_parent]').trigger("sticky_kit:recalc");

                  if (typeof spacer != undefined) {
                      spacer.css('height', ($('.filter_wrap-scroll').outerHeight() + 20));
                  }

                });

                var flag = false;
                
                $('input[name="postcode"]').change(function(){
                  var obj = $(this);
                  var postalCode = obj.val();

                  if(postalCode.length >= 4) {
                    $.ajax({
                       url: 'https://dutchblueclients.nl/postal/api.php?postal=' + postalCode,
                       type: "GET",
                       jsonpCallback: 'callback',
                       crossDomain: true,
                       success: function (response) {

                         if( response.status === "true") {
                           obj.parent().find('#adress').html(response.place);

                           $("#sorting").val('sort_distance');
                           updateListing();

                         } else {
                           obj.parent().find('#adress').html('Postcode onbekend');
                         }
                         
                         $(document.body).trigger("sticky_kit:recalc");
                       },
                    });
                  } else {
                    obj.parent().find('#adress').text('');
                  }

                });

                var wait;
                $('input#search').keyup(function() {
                  var obj = $(this);

                  clearTimeout(wait);

                  wait = setTimeout(function(){
                    if (obj.val().length > 3) {
                      $('.change_loader').hide();
                      $('.change_loader').velocity('fadeIn');

                      updateListing();
                    } else {
                      updateListing();
                    }
                  }, 750);
                  
                });

                var flag = false;
                $(document).on('click touchstart', '#load_all', function(){
                  if (!flag) {

                      $('.change_loader').hide();
                      $('.change_loader').velocity('fadeIn');

                      flag = true;

                      var filters;
                          categorie = [];
                          branche = [];
                          isocertificaat = [];

                      $('.field.categorie input:checked').each(function() {
                          categorie.push($(this).val());
                      });
                      $('.field.branche input:checked').each(function() {
                          branche.push($(this).val());
                      });
                      $('.field.iso input:checked').each(function() {
                          isocertificaat.push($(this).val());
                      });

                      var postcode = $('#postcode').val();
                          distance = $('#range').val();
                          sorting = $('#sorting').val();
                          werkplekken = $('.field.werkplekken input[name=werkplekken]:checked').val();
                          search = $('#search').val();

                      filters = {
                        'branches': branche,
                        'distance': distance,
                        'isocertificaten': isocertificaat,
                        'specialisaties': categorie,
                        'postcode': postcode,
                        'werkplekken': werkplekken,
                        'search': search
                      };

                      $.post(
                          ipgglobal.ajax_url, {
                            'action': 'get_partners',
                            'nonce': ipgglobal.nonce,
                            'filter': filters,
                            'sorting': sorting,
                            'number': -1
                          },
                          function (response) {
                            $('#alertContainer').empty();
                            if (response.success) {
                              $('#partners').html(response.data.output);
                              $('.liquid').imgLiquid();

                              $('.change_loader').velocity('fadeOut');

                            } else {
                              showAlert('danger', response.data.message, -100);
                            }
                          }
                      );

                    setTimeout(function(){ flag = false; }, 100);
                  }

                  return false

                });

                function updateListing() {
                  var filters;
                      categorie = [];
                      branche = [];
                      isocertificaat = [];
                      werkplekken = '';
                      search = '';

                  $('.field.categorie input:checked').each(function() {
                      categorie.push($(this).val());
                  });
                  $('.field.branche input:checked').each(function() {
                      branche.push($(this).val());
                  });
                  $('.field.iso input:checked').each(function() {
                      isocertificaat.push($(this).val());
                  });

                  var postcode = $('#postcode').val();
                      distance = $('#range').val();
                      sorting = $('#sorting[name="sorting"]').val();
                      werkplekken = $('.field.werkplekken input[name=werkplekken]:checked').val();
                      search = $('#search').val();
                  
                  filters = {
                    'branches': branche,
                    'distance': distance,
                    'isocertificaten': isocertificaat,
                    'specialisaties': categorie,
                    'postcode': postcode,
                    'werkplekken': werkplekken,
                    'search': search
                  };

                  $.post(
                      ipgglobal.ajax_url, {
                        'action': 'get_partners',
                        'nonce': ipgglobal.nonce,
                        'filter': filters,
                        'sorting': sorting,
                      },
                      function (response) {
                        $('#alertContainer').empty();
                        $('.change_loader').velocity('fadeOut');

                        if (response.success) {
                          $('#partners').html(response.data.output);
                          $('.liquid').imgLiquid();
                        
                          $('#partner-count').html($('#partners .partner').length);
                          
                          if(response.data.output.length === 0) {
                            $('#partner-count').html('Geen');
                            $("#partners").html('<p class="not-found">We hebben helaas geen ICT-bedrijf kunnen vinden die aan jouw zoekopdracht voldoen.</p><div class="active_filters"><h3 class="active_filter_title">Verwijder één van uw filters</div></div>');

                            $.each(filters, function(k, v) {
                            
                                if(k == 'specialisaties' && typeof v != 'undefined') {
                                  var arrayLength = v.length;
                                  for (var i = 0; i < arrayLength; i++) {
                                      var spec_name = $('input[value="' + v[i] + '"]').parent().text();
                            
                                      $('.active_filters').append('<div data-type="categorie" data-value="'+ v[i] +'"class="option">' + spec_name +'<i class="fa fa-times" aria-hidden="true"></i></div>');
                                  }
                                }

                                if(k == 'branches' && typeof v != 'undefined') {
                                  var arrayLength = v.length;
                                  for (var i = 0; i < arrayLength; i++) {
                                      var spec_name = $('input[value="' + v[i] + '"]').parent().text();
                                
                                      $('.active_filters').append('<div data-type="branche" data-value="'+ v[i] +'"class="option">' + spec_name +'<i class="fa fa-times" aria-hidden="true"></i></div>');
                                  }
                                }

                                if(k == 'isocertificaten' && typeof v != 'undefined') {
                                  var arrayLength = v.length;
                                  for (var i = 0; i < arrayLength; i++) {
                                      var spec_name = $('input[value="' + v[i] + '"]').parent().text();
                                
                                      $('.active_filters').append('<div data-type="iso" data-value="'+ v[i] +'"class="option">' + spec_name +'<i class="fa fa-times" aria-hidden="true"></i></div>');
                                  }
                                }

                                if(k == 'werkplekken' && typeof v != 'undefined') {
                                  var spec_name = $('input[value="' + v + '"]').parent().text();
                                  $('.active_filters').append('<div data-type="werkplekken" data-value="'+ v +'"class="option">' + spec_name +'<i class="fa fa-times" aria-hidden="true"></i></div>');
                                }
                     
                                if(k == 'search' && typeof v != 'undefined') {
                                  console.log(v);
                                  $('.active_filters').append('<div data-type="search" data-value="'+ v +'"class="option">Zoekopdracht: ' + v +'<i class="fa fa-times" aria-hidden="true"></i></div>');
                                }

                                if(k === 'distance' && distance !== '100') {
                                  $('.active_filters').append('<div data-type="distance" data-value="'+ v +'"class="option">' + v +' Km<i class="fa fa-times" aria-hidden="true"></i></div>');
                                }

                            });

                            $('body, html').velocity('scroll',{duration: 500, offset: 0});

                          }

                        } else {
                          showAlert('danger', response.data.message, -100);
                        }
                      }
                  );
                }

                flag = false;
                $(document).on('click', '.active_filters .option', function(){

                  if (!flag) {
                    flag = true;

                    $('.change_loader').hide();
                    $('.change_loader').velocity('fadeIn');

                    var type = $(this).attr('data-type');
                    var value = $(this).attr('data-value');
                    var elm = '.' + type;
                    
                    if(type === 'categorie' || type === 'branche' || type === 'iso' || type === 'werkplekken') {
                      $(elm + ' input[value="' + value + '"]').prop('checked', false);
                    }

                    if(type === 'search') {
                      $('input[name="search"]').val('');
                    }

                    if(type === 'distance') {
                      $("#range" ).val(100).slider('refresh');

                      var range = $('.sidebar-collapse #distance');
                      range.html('Alle afstanden');
                    }

                    updateListing();

                    setTimeout(function(){ flag = false; }, 500);
                  }

                  return false;
                });

                var wait;

                $('.sidebar-collapse .field input').change(function() {
                  var obj = $(this);
                
                  clearTimeout(wait);

                  wait = setTimeout(function(){
                    $('.change_loader').hide();

                    if( !obj.is('#range')) {
                      $('.change_loader').velocity('fadeIn');
                      updateListing();
                    } else {
                      if ($('#postcode').val().length >= 4) {
                        $('.change_loader').velocity('fadeIn');
                        updateListing();
                      }
                    }
                  }, 750);

                });

                $('.sidebar-collapse #postcode').keyup(function() {
                  
                  clearTimeout(wait);

                  wait = setTimeout(function(){
                    var obj = $(this);
                    if (obj.val().length >= 6) {
                      $('.change_loader').hide();
                      $('.change_loader').velocity('fadeIn');
                      updateListing();
                    }
                  }, 500);
                  
                });

                $('#sorting').change(function() {
                  clearTimeout(wait);
                  
                  var obj = $(this);

                  wait = setTimeout(function(){
                    $('.change_loader').hide();
                    $('.change_loader').velocity('fadeIn');
                    
                    if (obj.val() === 'distance') {
                      if( $('#postcode').val().length >= 4) {
                        updateListing();
                      }
                    } else {
                      updateListing();
                    }
                  }, 100);

                });

                $('.sidebar-collapse #range').change(function() {
                  var obj = $(this);
                  var val = obj.val();
                  var range = $('.sidebar-collapse #distance');

                  if (val === '100') {
                    range.html('Alle afstanden');
                  } else {
                    range.html(val + ' km');
                  }
                });

                $('#listing').on('click', '.more-go', function() {
                  var partner = $(this).attr('data-partner');

                  $('.partner .meer-informatie').show();
                  $('.partner .more-info').hide();
                  $('.partner[data-partner="'+ partner +'"] .more-info').show();
                  $('.partner[data-partner="'+ partner +'"] .more-info .more-info-content').html('');

                  $('body, html').velocity('scroll',{duration: 1000, offset: ($('.partner[data-partner="'+ partner +'"]').offset().top - 150) });

                  $('.partner[data-partner="'+ partner +'"] .meer-informatie').hide();

                  $('.loader_wrap').velocity('fadeIn');

                  $.post(
                      ipgglobal.ajax_url, {
                        'action': 'open_partner_single',
                        'nonce': ipgglobal.nonce,
                        'partner': partner,
                      },
                      function (response) {
                        $('.partner[data-partner="'+ partner +'"] .loader_wrap').hide();
                        $('.partner[data-partner="'+ partner +'"] .more-info .more-info-content').html(response);
                        
                        $('.liquid').imgLiquid();

                        setTimeout(function(){
                          var lat = $('.partner[data-partner="'+ partner +'"] .partner-single').attr('data-lat');
                          var long = $('.partner[data-partner="'+ partner +'"] .partner-single').attr('data-long');
                          var img_url = $('.partner[data-partner="'+ partner +'"] .partner-single').attr('data-url');

                          if(lat.length > 0 && long.length > 0) {
                            var myLatlng = new google.maps.LatLng(lat, long);

                            console.log(lat + ' ' + long);
                            var map = new google.maps.Map(document.getElementById('map'), {
                              center: myLatlng,
                              zoom: 8
                            });

                            var image = img_url + '/dist/images/pointer.png';
                            var marker = new google.maps.Marker({
                              position: myLatlng,
                              map: map
                            });
                          } else {
                            $('#map').remove();
                          }
                        }, 750);
                      }
                  );
                });

                $('.partner .more-info').on('click', '.close', function() {
                  $('#single').velocity(
                    'fadeOut', {
                      display: 'none',
                      duration: 500,
                  });

                  $('#listing').velocity({
                      marginTop: '0px',
                      opacity: 1,
                    }, {
                      delay: 500,
                      display: 'block',
                      duration: 500,
                  });
                });

            },
            finalize: function() {

            }
        },
        'partners': {
          init: function() {
          },
          finalize: function() {
            $('.open-partner').click(function() {
              var partner = $(this).attr('data-id');

              $.post(
                ipgglobal.ajax_url, {
                  'action': 'editor_open_partner',
                  'nonce': ipgglobal.nonce,
                  'partner': partner,
                },
                function (response) {
                  if (response.success) {
                    $('#modal').html(response.data.output);
                    $('.wrapper-modal').velocity('fadeIn');
                  } else {
                    showAlert('danger', response.data.message);
                  }
                }
              );
            });

            $('#modal').on('click', '#approvePartner', function() {
              var partner = $(this).attr('data-id');

              $.post(
                  ipgglobal.ajax_url, {
                    'action': 'approve_partner',
                    'nonce': ipgglobal.nonce,
                    'partner': partner
                  },
                  function (response) {
                    if(response.success) {
                      window.location.reload(true);
                    } else {
                      showAlert('danger', response.data.message);
                    }
                  }
              );
            });

            $('#modal').on('click', '#updatePartnerProfile', function() {

              if( $(this).prop('disabled') === false && $('#partnerProfileForm')[0].checkValidity()) {
                $(this).prop('disabled', true);

                var partner = $(this).attr('data-id');

                var partnerProfileFormData = {};
                $.each( $('#partnerProfileForm').serializeArray(), function(i, field) {
                  partnerProfileFormData[field.name] = field.value;
                });
                partnerProfileFormData = JSON.stringify( partnerProfileFormData );

                var image = false;
                if ($('#imageSelect')[0].files[0]) {
                  var file = $('#imageSelect')[0].files[0];
                  if (file.type.match('image.*') && file.size/1024 <= 500) {
                    image = file;
                  } else {
                    $('#updatePartnerProfile').prop('disabled', false);
                    showAlert('danger', 'De gekozen afbeelding kon niet worden ingesteld. Kies een .jpg, .jpeg of .png bestand met een grootte van maximaal 500KB.');
                    return;
                  }
                }

                var form_data = new FormData();
                form_data.append('action', 'editor_update_partner_profile');
                form_data.append('nonce', ipgglobal.nonce);
                form_data.append('partner', partner);
                form_data.append('profileData', partnerProfileFormData);
                if (image) {
                  form_data.append('image', image);
                }

                $.ajax({
                  url: ipgglobal.ajax_url,
                  dataType: 'json',
                  cache: false,
                  processData: false,
                  contentType: false,
                  data: form_data,
                  type: "POST",
                  success: function(data, textStatus, jqXHR) {
                    if (data.success) {
                      $('#updatePartnerProfile').prop('disabled', false);
                      window.location.reload(true);
                    } else {
                      $('#updatePartnerProfile').prop('disabled', false);
                      showAlert('danger', data.data.message);
                    }
                  },
                  error: function(data, textStatus, jqXHR) {
                    $('#updatePartnerProfile').prop('disabled', false);
                    showAlert('danger', data.data.message);                    },
                });
              }

            });

          },
        },
        'profiel': {
            init: function() {
            },
            finalize: function() {
              $('#collapseVendoren input[type="checkbox"]').change(function() {
                var wrapper = $('.partner-status-wrapper[data-vendor="' + $(this).val() + '"]');
                if (this.checked) {
                  wrapper.show();
                } else {
                  wrapper.hide();
                  wrapper.find('input').prop('checked', false);
                }
              });

              $('#collapseUnregisteredVendoren input[type="checkbox"]').change(function() {
                var wrapper = $('.partner-status-wrapper[data-vendor="' + $(this).val() + '"]');
                if (this.checked) {
                  wrapper.show();
                } else {
                  wrapper.hide();
                  wrapper.find('input').prop('checked', false);
                }
              });

              $(document).on('click', '.location .fa-times', function(){
                $(this).parent().parent().remove();
              });

              $('.add_location').click(function(){
                var count = $('.locations .location').length;
                if(count < 3) {
                   $('.locations').append('<div class="location" data-location="' + (count + 1) + '"><label for="postcode_loc">Postcode <i class="fa fa-times" aria-hidden="true"></i></label><input type="text" name="postcode_loc[]" placeholder="bijv. 3077ag"></div>');
                }
              });

              $('#updatePartnerProfile').click(function() {

                if( $(this).prop('disabled') === false && $('#partnerProfileForm')[0].checkValidity()) {
                  $(this).prop('disabled', true);

                  var partnerProfileFormData = {};
                  $.each( $('#partnerProfileForm').serializeArray(), function(i, field) {
                    partnerProfileFormData[field.name] = field.value;
                  });
                  partnerProfileFormData = JSON.stringify( partnerProfileFormData );

                  var partnerDetailsFormData = {};
                      vendoren = [];
                      statusID = false;
                      vendorObject = false;


                  $.each( $('#partnerDetailsForm').serializeArray(), function(i, field) {
                    if (field.name === 'specialisaties[]') {
                      var specialisaties = [];
                      $('#collapseSpecialisaties input:checked').each(function() {
                        specialisaties.push( $(this).val());
                      });
                      partnerDetailsFormData.specialisaties = specialisaties;
                    } else if (field.name === 'branches[]') {
                      var branches = [];
                      $('#collapseBranches input:checked').each(function() {
                        branches.push( $(this).val());
                      });
                      partnerDetailsFormData.branches = branches;
                    } else if (field.name === 'isocertificaten[]') {
                      var isocertificaten = [];
                      $('#collapseISO input:checked').each(function() {
                        isocertificaten.push( $(this).val());
                      });
                      partnerDetailsFormData.isocertificaten = isocertificaten;
                    } else if (field.name === 'vendoren[]') {
                      var vendorID = field.value;
                      partnerstatus = $('.partner-status-wrapper[data-vendor=' + vendorID + '] input:checked').val();
                      vendorObject = {'vendor': vendorID, 'partnerstatus': partnerstatus};

                      vendoren.push(vendorObject);
                    } else if (field.name === 'unregisteredvendoren[]') {
                      var vendorNaam = field.value;
                      partnerstatus = $('.partner-status-wrapper[data-vendor=' + vendorNaam + '] input:checked').val();
                      vendorObject = {'vendor_naam': vendorNaam, 'partnerstatus': partnerstatus};

                      vendoren.push(vendorObject);
                    } else {
                      partnerDetailsFormData[field.name] = field.value;
                    }
                  });

                  partnerDetailsFormData.vendoren = vendoren;
                  
                  $.each( $('#locationsForm').serializeArray(), function(i, field) {
                    if (field.name === 'postcode_loc[]') {
                      var postcode_loc = [];
                      $('.location input').each(function() {
                        postcode_loc.push( $(this).val());
                      });
                      partnerDetailsFormData.postcode_loc = postcode_loc;
                    }
                  });

                  partnerDetailsFormData = JSON.stringify( partnerDetailsFormData );

                  console.log(partnerDetailsFormData);
                  var image = false;
                  if ($('#imageSelect')[0].files[0]) {
                    var file = $('#imageSelect')[0].files[0];
                    if (file.type.match('image.*') && file.size/1024 <= 500) {
                      image = file;
                    } else {
                      $('#updatePartnerProfile').prop('disabled', false);
                      showAlert('danger', 'De gekozen afbeelding kon niet worden ingesteld. Kies een .jpg, .jpeg of .png bestand met een grootte van maximaal 500KB.');
                      return;
                    }
                  }

                  var form_data = new FormData();
                  form_data.append('action', 'partner_update_profile');
                  form_data.append('nonce', ipgglobal.nonce);
                  form_data.append('profileData', partnerProfileFormData);
                  form_data.append('detailsData', partnerDetailsFormData);
                  if (image) {
                    form_data.append('image', image);
                  }

                  $.ajax({
                    url: ipgglobal.ajax_url,
                    dataType: 'json',
                    cache: false,
                    processData: false,
                    contentType: false,
                    data: form_data,
                    type: "POST",
                    success: function(data, textStatus, jqXHR) {
                      console.log(data);
                      if (data.success) {
                        $('#updatePartnerProfile').prop('disabled', false);
                        //window.location.reload(true);
                      } else {
                        $('#updatePartnerProfile').prop('disabled', false);
                        showAlert('danger', data.data.message);
                      }
                    },
                    error: function(data, textStatus, jqXHR) {
                      $('#updatePartnerProfile').prop('disabled', false);
                      showAlert('danger', data.data.message);                    
                    },
                  });
                }

              });

              $('#updateVendorProfile').click(function() {

                if( $(this).prop('disabled') === false && $('#vendorProfileForm')[0].checkValidity()) {
                    $(this).prop('disabled', true);

                    var vendorProfileFormData = {};
                    $.each( $('#vendorProfileForm').serializeArray(), function(i, field) {
                    vendorProfileFormData[field.name] = field.value;
                    });
                    vendorProfileFormData = JSON.stringify( vendorProfileFormData );

                    var vendorDetailsFormData = {};
                    $.each( $('#vendorDetailsForm').serializeArray(), function(i, field) {
                      if (field.name === 'categorie[]') {
                        var categorie = [];
                        $('#categorie input:checked').each(function() {
                          categorie.push( $(this).val());
                        });
                        vendorDetailsFormData.categorie = categorie;
                      } else {
                        vendorDetailsFormData[field.name] = field.value;
                      }
                    });
                    vendorDetailsFormData = JSON.stringify( vendorDetailsFormData );

                    var image = false;
                    if ($('#imageSelect')[0].files[0]) {
                      var file = $('#imageSelect')[0].files[0];
                      if (file.type.match('image.*') && file.size/1024 <= 500) {
                        image = file;
                      } else {
                        $('#updateVendorProfile').prop('disabled', false);
                        showAlert('danger', 'De gekozen afbeelding kon niet worden ingesteld. Kies een .jpg, .jpeg of .png bestand met een grootte van maximaal 500KB.');
                        return;
                      }
                    }

                    var form_data = new FormData();
                    form_data.append('action', 'vendor_update_profile');
                    form_data.append('nonce', ipgglobal.nonce);
                    form_data.append('profileData', vendorProfileFormData);
                    form_data.append('detailsData', vendorDetailsFormData);
                    if (image) {
                      form_data.append('image', image);
                    }

                    $.ajax({
                      url: ipgglobal.ajax_url,
                      dataType: 'json',
                      cache: false,
                      processData: false,
                      contentType: false,
                      data: form_data,
                      type: "POST",
                      success: function(data, textStatus, jqXHR) {
                        console.log(data);
                        if (data.success) {
                          $('#updateVendorProfile').prop('disabled', false);
                          window.location.reload(true);
                        } else {
                          $('#updateVendorProfile').prop('disabled', false);
                          showAlert('danger', data.data.message);
                        }
                      },
                      error: function(data, textStatus, jqXHR) {
                        $('#updateVendorProfile').prop('disabled', false);
                        showAlert('danger', data.data.message);
                      },
                    });
                  }
              });

              $('#imgUploadWrapper .fa').click(function() {
                $('#imageSelect').click();
              });

              $('#imageSelect').change(function() {

                if ($('#imageSelect')[0].files[0]) {
                  var file = $('#imageSelect')[0].files[0];
                  if (file.type.match('image.*') && file.size/1024 <= 500) {
                    $('#imgUploadWrapper .fa').velocity({
                      backgroundColor: '#079615',
                      padding: '10px',
                    }, {
                      complete: function() {
                        $('#imgUploadWrapper .fa').removeClass('fa-pencil-square-o fa-times');
                        $('#imgUploadWrapper .fa').addClass('fa-check');
                      },
                      delay: 1000,
                      duration: 200,
                      easing: 'easeOutCubic',
                    });
                  } else {
                    $('#imgUploadWrapper .fa').velocity({
                      backgroundColor: '#91131e',
                      paddingTop: '10px',
                      paddingRight: '11.75px',
                      paddingBottom: '10px',
                      paddingLeft: '11.75px',
                    }, {
                      complete: function() {
                        $('#imgUploadWrapper .fa').removeClass('fa-pencil-square-o fa-check');
                        $('#imgUploadWrapper .fa').addClass('fa-times');
                      },
                      delay: 1000,
                      duration: 200,
                      easing: 'easeOutCubic',
                    });
                  }
                }
              });
            }
        },
        'vendor_aanmelden': {
          init: function() {
          },
          finalize: function() {
            $('#signupVendor').on('click', function(e) {
              //s
              if( $(this).prop('disabled') === false && $('#signupForm')[0].checkValidity()) {
                $(this).prop('disabled', true);
                var password = $('#password').val();
                var confirmPassword = $('#confirmPassword').val();

                var signupFormData = {};
                $.each( $('#signupForm').serializeArray(), function(i, field) {
                  signupFormData[field.name] = field.value;
                });
                signupFormData = JSON.stringify( signupFormData );

                if( password === confirmPassword) {
                  $.post(
                      ipgglobal.ajax_url, {
                        'action': 'signup_vendor',
                        'nonce': ipgglobal.nonce,
                        'data': signupFormData
                      },
                      function (response) {
                        if(response.success) {
                          window.location = ipgglobal.site_url + '/aanmelding-ontvangen';
                        } else {
                          showAlert('danger', response.data.message);
                        }
                        $('#signupVendor').prop('disabled', false);
                      }
                  );
                } else {
                  showAlert('danger', 'De wachtwoorden komen niet overeen.');
                  $('#signupVendor').prop('disabled', false);
                }
              } else {
                showAlert('danger', 'Niet alle velden van het inschrijvingsformulier zijn goed ingevuld.');
                $('#signupVendor').prop('disabled', false);
              }
            });
          }
        },
        'vendoren': {
          init: function() {
          },
          finalize: function() {
            $('.open-vendor').click(function() {
              var vendor = $(this).attr('data-id');

              $.post(
                ipgglobal.ajax_url, {
                  'action': 'editor_open_vendor',
                  'nonce': ipgglobal.nonce,
                  'vendor': vendor,
                },
                function (response) {
                  if (response.success) {
                    $('#modal').html(response.data.output);
                    $('.wrapper-modal').velocity('fadeIn');
                  } else {
                    showAlert('danger', response.data.message);
                  }
                }
              );
            });

            $('#modal').on('click', '#approveVendor', function() {
              var vendor = $(this).attr('data-id');

              $.post(
                  ipgglobal.ajax_url, {
                    'action': 'approve_vendor',
                    'nonce': ipgglobal.nonce,
                    'vendor': vendor
                  },
                  function (response) {
                    if(response.success) {
                      window.location.reload(true);
                    } else {
                      showAlert('danger', response.data.message);
                    }
                  }
              );
            });

            $('#modal').on('click', '#updateVendorProfile', function() {

              if( $(this).prop('disabled') === false && $('#vendorProfileForm')[0].checkValidity()) {
                $(this).prop('disabled', true);

                var vendor = $(this).attr('data-id');

                var vendorProfileFormData = {};
                $.each( $('#vendorProfileForm').serializeArray(), function(i, field) {
                  vendorProfileFormData[field.name] = field.value;
                });
                vendorProfileFormData = JSON.stringify( vendorProfileFormData );

                var image = false;
                if ($('#imageSelect')[0].files[0]) {
                  var file = $('#imageSelect')[0].files[0];
                  if (file.type.match('image.*') && file.size/1024 <= 500) {
                    image = file;
                  } else {
                    $('#updateVendorProfile').prop('disabled', false);
                    showAlert('danger', 'De gekozen afbeelding kon niet worden ingesteld. Kies een .jpg, .jpeg of .png bestand met een grootte van maximaal 500KB.');
                    return;
                  }
                }

                var form_data = new FormData();
                form_data.append('action', 'editor_update_vendor_profile');
                form_data.append('nonce', ipgglobal.nonce);
                form_data.append('vendor', vendor);
                form_data.append('profileData', vendorProfileFormData);
                if (image) {
                  form_data.append('image', image);
                }

                $.ajax({
                  url: ipgglobal.ajax_url,
                  dataType: 'json',
                  cache: false,
                  processData: false,
                  contentType: false,
                  data: form_data,
                  type: "POST",
                  success: function(data, textStatus, jqXHR) {
                    if (data.success) {
                      $('#updateVendorProfile').prop('disabled', false);
                      window.location.reload(true);
                    } else {
                      $('#updateVendorProfile').prop('disabled', false);
                      showAlert('danger', data.data.message);
                    }
                  },
                  error: function(data, textStatus, jqXHR) {
                    $('#updateVendorProfile').prop('disabled', false);
                    showAlert('danger', data.data.message);                    },
                });
              }

            });

          },
        },
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
